import EXPERIENCES from "constants/portalExperiences";

export const QUALIFICATION_API_UNAVAILABLE = "qualification API unavailable";
export const NOTIFICATION_API_UNAVAILABLE = "notification API unavailable";

export const constructQualificationString = (delimiter, ...values) => {
  if (values.every(value => value !== undefined)) {
    return values.join(delimiter);
  }
  return undefined;
};

export const getNotificationsLoginCompletedEventValues = notifications => {
  const feesOwed = notifications && notifications.includes("PAYMENT_OVERDUE");
  const cpdStatus = notifications && notifications.includes("CPD_DECLARATION_OVERDUE_ONE");

  return { feesOwed, cpdStatus };
};

export const getQualificationsLoginCompletedEventValues = (qualifications, portalExperience) => {
  const isMember = portalExperience === EXPERIENCES.MEMBER;

  const examProgress =
    constructQualificationString(
      "/",
      qualifications?.exams?.completed,
      qualifications?.exams?.total
    ) || (!isMember ? "0/9" : undefined);

  const ethicsProgress =
    qualifications?.moduleStatus && qualifications?.moduleStatus.indexOf("NOT_") === -1 ? 1 : 0;

  const ethicsStatus = !isMember ? `${ethicsProgress}/1` : undefined;

  const employmentTotalClaimed = qualifications?.practicalExperience?.employment?.totalClaimed;
  const employmentTotalAchieved = qualifications?.practicalExperience?.employment?.totalAchieved;
  const objectivesTotalAchieved =
    qualifications?.practicalExperience?.objectives?.totalAchievedObjectives;
  const perStatus =
    constructQualificationString(
      "|",
      employmentTotalClaimed,
      employmentTotalAchieved,
      objectivesTotalAchieved
    ) || (!isMember ? "not started" : undefined);

  return { examProgress, ethicsStatus, perStatus };
};

export const formatAccaStatus = accaStatus => {
  const lowercasedAccaStatus = accaStatus.toLowerCase();
  const capitalizedString =
    lowercasedAccaStatus.charAt(0).toUpperCase() + lowercasedAccaStatus.slice(1);
  return capitalizedString;
};

export const getFormattedUserDetails = (
  hasNotificationsError,
  hasQualificationsError,
  notifications,
  qualifications,
  portalExperience
) => {
  let qualificationsLoginCompletedEventValues = {};
  let notificationsLoginCompletedEventValues = {};

  if (hasNotificationsError) {
    notificationsLoginCompletedEventValues = {
      feesOwed: NOTIFICATION_API_UNAVAILABLE,
      cpdStatus: NOTIFICATION_API_UNAVAILABLE,
    };
    qualificationsLoginCompletedEventValues = {
      examProgress: NOTIFICATION_API_UNAVAILABLE,
      ethicsStatus: NOTIFICATION_API_UNAVAILABLE,
      perStatus: NOTIFICATION_API_UNAVAILABLE,
    };
  } else if (hasQualificationsError) {
    notificationsLoginCompletedEventValues = getNotificationsLoginCompletedEventValues(
      notifications
    );

    qualificationsLoginCompletedEventValues = {
      examProgress: QUALIFICATION_API_UNAVAILABLE,
      ethicsStatus: QUALIFICATION_API_UNAVAILABLE,
      perStatus: QUALIFICATION_API_UNAVAILABLE,
    };
  } else {
    notificationsLoginCompletedEventValues = getNotificationsLoginCompletedEventValues(
      notifications
    );

    qualificationsLoginCompletedEventValues = getQualificationsLoginCompletedEventValues(
      qualifications,
      portalExperience
    );
  }

  return { notificationsLoginCompletedEventValues, qualificationsLoginCompletedEventValues };
};
